@mixin en {
  html[lang=en] & {
    @content; } }

body.questionnaire {
  direction: rtl;
  font: {
    family: sofia-pro, 'Heebo', sans-serif;
    size: 21px; }
  color: $gray-main;
  counter-reset: question;

  @include en {
    direction: ltr;
    text-align: left; }

  &.snap {
    @include not-small {
      scroll-snap-type: y mandatory;
      scroll-snap-stop: always;
      scroll-snap-align: start; } }

  .wrapper {
    padding-bottom: 0; }

  .page-header {
    .texts {
      max-width: 32em;

      @include en {
        max-width: 36em;
        margin: 0 auto;

        @include small {
          transform: translateY(24px); } }

      p {
        margin-top: .6em;
        line-height: 1.25; } } }

  .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: $white;
    border-top: solid 1px $gray-bg-dark;
    z-index: 1;

    .content {
      padding: 0 5vw;
      @include full-size-absolute;
      @include flex-space; }

    .action {
      display: flex;
      align-items: center; }

    .counter {
      margin-left: 12px;
      font-size: .8em;
      color: $gray-main;
      flex-shrink: 0;

      @include en {
        margin-right: 12px;
        margin-left: 0; }

      &.full {
        color: $blue; } }

    span.of {
      display: inline-block;
      margin: 0 .3em; }

    .logo-wide {
      @include small {
        font-size: .65em; } } }

  h1 {
    font: {
      size: 36px;
      weight: 700; }
    margin-bottom: 0; }

  .logo {
    animation: none; }

  .field {
    scroll-snap-align: center;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 36em;
    margin: 0 auto;

    &.question {
      counter-increment: question;

      label {
        &:before {
          content: counter(question);
          position: absolute;
          right: -2em;
          display: inline-block;
          transform: translateY(1em);
          opacity: .5;
          margin-left: .33em;
          font: {
            size: .5em;
            weight: 700; }

          @include en {
            right: auto;
            left: -2em; } } }

      p {
        font-size: .8em;
        opacity: .5;
        margin-top: 0; } } }

  input, textarea {
    -webkit-appearance: none;
    font-size: 1.5em;
    color: $blue;
    padding: .5em 0 .5em;
    border: solid 1px transparent;
    border-bottom-color: $blue;
    transition: $transition-quick;
    width: 100%;

    &:not(.editable) {
      &.hidden {
        @include hidden;
        position: absolute; }

      &:focus,
      &:not(:placeholder-shown) {
        &, & + .editable {
          outline: none;

          @include not-small {
            border-bottom-color: transparent; } } }

      &:not(:placeholder-shown) {
        &, & + .editable {
          border-bottom-color: transparent;

          ~ .next {
            transition: $transition-quick $duration-quick;
            @include unhidden; } } } } }

  textarea {
    resize: none;
    overflow: hidden;
    //padding: 0
    font-size: 1.25em; }

  label {
    display: block;

    &.required {
      &:after {
        content: '*';
        display: inline-block;
        margin-right: .25em;
        font-size: .75em;
        transform: translateY(-.2em);
        color: $blue; } } }

  .next {
    margin-top: 24px;
    @include hidden;
    display: flex;
    align-items: center;

    span {
      margin-right: 1em;

      @include en {
        margin-left: 1em; } } }

  .enter, .shift-enter {
    display: block;
    font-size: .8em;
    opacity: .5; }

  .shift-enter {
    color: $blue;
    font-size: .65em;

    @include small {
      display: none; } }

  .button {
    display: inline-block;
    padding: .5em 1.5em;
    line-height: 1;
    background-color: $blue;
    border-radius: 6px;
    color: $white;
    font-weight: 700;
    cursor: pointer;
    transition: $transition-quick;

    @include en {
      padding-bottom: .67em; }

    &:disabled {
      opacity: .5;
      filter: saturate(0);
      cursor: initial; }

    &:not(:disabled) {
      @include hover {
        background-color: darken($blue, 5%); } }

    &.submit {
      align-self: center;
      font-size: 1em; } }

  .editable {
    @extend textarea;

    &:focus {
      outline: none; } } }

body.questionnaire.thanks {
  font-family: 'Heebo', sans-serif;

  @include en {
    font-family: sofia-pro, sans-serif; }

  .page-header {
    width: 100vw;
    height: 100vh;
    @include flex-center;
    text-align: center;

    h1 {
      font: {
        weight: 300;
        size: 3.5em; } } } }
