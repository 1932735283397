@import "var";
@import "media";

@mixin horizontal-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block; } }

@mixin full-size {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

@mixin full-size-absolute {
  @include full-size;
  position: absolute; }

@mixin full-size-fixed {
  @include full-size;
  position: fixed; }

@mixin content-width {
  max-width: $grid-width;
  width: 100%;
  padding: 0 12rem;

  @include medium {
    max-width: 100%;
    padding: 0 $margin-mid; }

  @include small {
    padding: 0 $margin-small; } }

@mixin hidden {
  opacity: 0;
  pointer-events: none; }

@mixin unhidden {
  opacity: 1;
  transform: none;
  pointer-events: auto; }

@mixin scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

@mixin hover {
  html:not(.mobile):not(.tablet) & {
    &:hover {
      @content; } } }

@mixin focussed {
  @include hover {
    @content; }
  &:focus {
    @content; } }

@mixin scroll-height-auto {
  height: auto;
  height: var(--scroll-height, auto); }

@mixin shadow($color, $percentage: 40%, $length: 2.5vh) {
  box-shadow: $length $length $length*2 darken($color, $percentage); }

@mixin background-blur($color, $blur, $blurOpacity, $opacity: 1) {
  background-color: transparentize($color, 1 - $opacity);

  @supports (-webkit-backdrop-filter: blur($blur)) {
    -webkit-backdrop-filter: blur($blur);
    background-color: transparentize($color, 1 - $blurOpacity); } }

@mixin flex-center($direction: column) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center; }

@mixin flex-space($direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: space-between;
  align-items: center; }

@mixin type-min-max($size, $direction, $max-px, $min-px: 1px) {
  font-size: $size + $direction;

  @media screen and (min-width: $max-px / $size * 100) {
    font-size: $max-px; }

  @media screen and (max-width: $min-px / $size * 100) {
    font-size: $min-px; } }

// http://alistapart.com/article/using-css-mod-queries-with-range-selectors
// mod query mixin
@mixin mod-list($mod, $remainder) {
  &:nth-last-child(#{$mod}n+#{$remainder}):first-child,
  &:nth-last-child(#{$mod}n+#{$remainder}):first-child ~ li {
    @content; } }

// Scrim gradient from https://codepen.io/joeyquarters/pen/dWJYdV
@mixin scrim($color: black, $direction: 'to bottom') {
  $scrimCoordinates: (0: 1, 19: 0.738, 34: 0.541, 47: 0.382, 56.5: 0.278, 65: 0.194, 73: 0.126, 80.2: 0.075, 86.1: 0.042, 91: 0.021, 95.2: 0.008, 98.2: 0.002, 100: 0);

  $hue: hue($color);
  $saturation: saturation($color);
  $lightness: lightness($color);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma); }

  background-image: linear-gradient(unquote($direction), $stops); }

@mixin stagger($count, $step, $base: 0s, $easing: true, $ease-factor: 0.33, $reverse: false, $data-index: false, $type: transition, $child: '') {

  $start: 1;
  $end: $count;

  @if ($easing == false) {
    $ease-factor: 0; }

  @if ($data-index == true) {
    $data-index: data-stagger-index; }
  @else {
    $data-index: unquote($data-index); }

  //& #{unquote($child)} // maybe: default to max

  @for $i from $start through $end {
    @if ($data-index) {
      &[#{$data-index}="#{$i}"] #{unquote($child)} {
        #{$type}-delay: #{($i - ($i - 1) * $ease-factor) * $step + $base}; } }

    @else if ($reverse) {
      &:nth-last-child(#{$i}) #{unquote($child)} {
        #{$type}-delay: #{($i - ($i - 1) * $ease-factor) * $step + $base}; } }

    @else {
      &:nth-child(#{$i}) #{unquote($child)} {
        #{$type}-delay: #{($i - ($i - 1) * $ease-factor) * $step + $base}; } } } }
