// sizes
$unit: 4rem;
$margin-min: $unit * 1.5;
$margin-small: $unit * 3;
$margin-mid: $margin-small * 2;
$margin-main: $margin-small * 3;
$margin-large: $margin-main * 2;
$margin-huge: $margin-main * 3;

$top-padding-horizontal: 72rem;
$top-padding-horizontal-m: 16rem;
$top-padding-vertical: 58rem;
$top-padding-vertical-m: 12rem;
$site-header-inner-height: 60rem;
$site-header-inner-height-scrolled: 38rem;
$site-header-outer-height-scrolled: 86rem;
$site-header-inner-height-m: 48rem;

$orange-button-width: 240rem;

$panel-radius: 22rem;

$label-transform: scale(.833) translateY(-1.5em);

// grid
$grid-gutter-width: 12rem;
$grid-cols: 12;
$grid-col-width: 91rem;
$grid-width: ($grid-col-width + $grid-gutter-width) * ($grid-cols - 1) + $grid-col-width;

@function grid-width($i) {
  @return ($i * $grid-col-width) + (($i - 1) * $grid-gutter-width); }

@mixin grid-width($i) {
  max-width: grid-width($i); }

// colors
$black: #000;
$white: #fff;

$gray-main: #424242;
$gray-bg: #FAFAFA;
$gray-bg-dark: #DCDDDE;

$gray-difference: #595959;

$blue: #00a7cb;

// transitions
$duration-long: 1.5s;
$duration-main: 0.8s;
$duration-mid: 0.5s;
$duration-quick: 0.15s;

$ease-spring: cubic-bezier(.69, -.15, 0, 1.33);
$ease-spring-flat: cubic-bezier(.69, 0, 0, 1);

$transition-long: all $duration-long ease-out;
$transition-main: all $duration-main ease-out;
$transition-mid: all $duration-mid ease-out;
$transition-quick: all $duration-quick ease-out;
$transition-spring: all $duration-main $ease-spring;
$transition-spring-flat: all $duration-main $ease-spring-flat;
