@import "bundle";
@import "reset";
$mobile-image-height: 45vw;

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(100vh); }

  80% {
    transform: translateY(-1.5vh); } }

@keyframes fade-in {
  from {
    opacity: 0; } }

html {
  font-size: 1px; }

body {
  font-family: sofia-pro, sans-serif;
  text-align: right;
  font-size: 17rem;
  color: $black; }

::selection {
  background-color: transparentize($black, 0.95);
  color: $white; }

a {
  color: inherit;
  text-decoration: none; }

video {
  display: block;
  width: 100%;
  height: auto;

  &.contain {
    @include small {
      height: $mobile-image-height;
      width: auto; } } }

.wrapper {
  padding: 5vw;
  width: 100vw;
  transform: translate3d(0,0,0);
  overflow: hidden;

  @include medium {
    margin-top: 5vw; } }

h1 {
  line-height: .9;
  cursor: default;
  letter-spacing: -0.035em;
  font: {
    size: 26vw;
    weight: 500; }

  @include large {
    margin-bottom: 15vw; }

  span {
    color: $black;
    display: block;
    position: relative;
    z-index: 1;

    &:nth-child(3),
    &:nth-child(6) {
      margin-top: 17.5vw;

      @include portrait-or-small {
        margin-top: $mobile-image-height; } } } }

[data-stagger-index] {
  animation: fade-up $duration-long ease-out 1 backwards;
  @include stagger(30, $duration-quick, $duration-main, $type: animation, $easing: true, $data-index: true); }

.email {
  display: inline-block;
  position: fixed;
  bottom: 5vw;
  left: 5vw;
  z-index: 99;
  margin-top: 13vw;
  transition: $transition-quick;
  text-shadow: .1em 0 0 $white, -.1em 0 0 $white;
  text-align: left;
  mix-blend-mode: multiply;
  font-size: 1.25em;

  @include medium {
    position: relative;
    bottom: 5vw;
    left: auto;
    right: 5vw;
    font-size: 2vw;
    text-align: right; }

  @include small {
    font-size: 17rem; }

  &::selection {
    text-shadow: none; }

  &:after {
    content: '';
    position: absolute;
    bottom: .1em;
    height: 2rem;
    left: .085em;
    right: .05em;
    z-index: -1;
    background-color: currentColor;
    transform-origin: right;
    transition: inherit; }

  &:hover {
    text-decoration: none;

    &:after {
      transform: translateY(200%);
      opacity: 0; } } }

.logo {
  position: fixed;
  height: 3vw;
  top: 5vw;
  left: 5vw;
  min-height: 20rem;
  animation: fade-in $duration-main ease-out $duration-mid 1 backwards;

  img {
    height: 100%;
    width: auto; } }

.images {
  .image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: transform 0.05s ease-in-out;

    @include medium {
      transition: none; }

    @include small {
      width: 90vw !important;
      left: 5vw !important;
      max-height: $mobile-image-height !important;
      @include flex-center;

      &.no-mobile {
        display: none; }

      &.half {
        width: 45vw !important;

        & + .half, & + .no-mobile + .half {
          left: 50vw !important; } } }

    img {
      width: 100%;
      height: auto; } } }

.about {
  position: absolute;
  left: 5vw;
  top: 9vw;
  text-align: left;
  max-width: 20vw;
  width: 16em;
  height: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: $transition-mid;

  @include medium {
    position: relative;
    top: 5vw;
    left: calc(95vw - 16em);
    max-width: 16em;
    width: auto;
    height: auto;
    text-align: right; }

  &.hidden {
    @include large {
      opacity: 0; } } }

@import "questionnaire";
