@import "var";

// sizes

$small: 767px;
$medium: 1222px + 24 + 24;
$large: 1920px;

// queries

@mixin small {
  @media screen and (max-width: $small) {
    @content; } }

@mixin not-small {
  @media screen and (min-width: $small + 1) {
    @content; } }

@mixin medium {
  @media screen and (max-width: $medium) {
    @content; } }

@mixin large {
  @media screen and (min-width: $medium + 1) {
    @content; } }

@mixin huge {
  @media screen and (min-width: $large + 1) {
    @content; } }

@mixin portrait {
  @media screen and (orientation: portrait) {
    @content; } }

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content; } }

@mixin portrait-or-small {
  @media screen and (orientation: portrait), (max-width: $small) {
    @content; } }

@mixin portrait-medium {
  @media screen and (orientation: portrait) and (min-width: $small) and (max-width: $medium) {
    @content; } }

@mixin landscape-medium {
  @media screen and (orientation: landscape) and (min-width: $small) and (max-width: $medium) {
    @content; } }

@mixin phone {
  html.mobile & {
    @content; } }

@mixin tablet {
  html.tablet & {
    @content; } }

@mixin desktop {
  html.desktop & {
    @content; } }

@mixin mobile {
  html.mobile & {
    @content; }

  html.tablet & {
    @content; } }

@mixin portrait-device {
  html.mobile & {
    @content; }

  html.tablet.portrait & {
    @content; } }

@mixin tablet-portrait {
  html.tablet.portrait & {
    @content; } }

@mixin tablet-landscape {
  html.tablet.landscape & {
    @content; } }
